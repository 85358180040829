var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-breadcrumbs',{staticClass:"pa-0",attrs:{"items":_vm.breadcrumbs,"large":""}})],1)],1),_c('v-row',[_c('v-col',[_c('wolk-private-data-alert')],1)],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v(" Gebruikers "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Zoeken","single-line":"","hide-details":""},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('v-spacer'),(_vm.$store.getters['auth/isAdminUser'] || _vm.$store.getters['auth/isAdmin'])?_c('v-btn',{attrs:{"to":{ name: 'usersNew' },"color":"primary"}},[_vm._v(" Voeg toe ")]):_vm._e()],1),_c('v-data-table',{staticStyle:{"white-space":"nowrap"},attrs:{"headers":_vm.headers,"items":_vm.filteredUsers,"items-per-page":20,"footer-props":{ itemsPerPageOptions: [10, 20, 50, 200] },"loading":_vm.loading,"must-sort":"","sort-by":_vm.tableSortBy,"sort-desc":_vm.tableSortDesc},on:{"update:sortBy":function($event){_vm.tableSortBy=$event},"update:sort-by":function($event){_vm.tableSortBy=$event},"update:sortDesc":function($event){_vm.tableSortDesc=$event},"update:sort-desc":function($event){_vm.tableSortDesc=$event}},scopedSlots:_vm._u([{key:"item.isAdmin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.isAdmin ? 'Ja' : 'Nee')+" ")]}},{key:"item.isViewOnly",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.isViewOnly ? 'Ja' : 'Nee')+" ")]}},{key:"item.allLocations",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.metaData.locationIds ? 'Nee' : 'Ja')+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getHumanizedUserType(item.userType))+" ")]}},{key:"item.organizationTitle",fn:function(ref){
var item = ref.item;
return [(item.metaData && item.metaData.organizationTitle)?_c('router-link',{attrs:{"to":{ name: 'organizationsShow', params: { id: item.metaData.organizationId }}}},[_vm._v(" "+_vm._s(item.metaData.organizationTitle)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.$router.push({ name: 'usersEdit', params: { id: item._id } })}}},[_vm._v(" mdi-pencil ")]),_c('wolk-delete-button',{attrs:{"question":("Wil je gebruiker " + (item.email) + " verwijderen?")},on:{"confirmed":function($event){return _vm.deleteUser(item)}}})]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }