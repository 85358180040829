<template>
  <v-container>
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0"
          large
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <wolk-private-data-alert />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Gebruikers

            <v-spacer />

            <v-text-field
              v-model="filter"
              append-icon="mdi-magnify"
              label="Zoeken"
              single-line
              hide-details
            />

            <v-spacer />

            <v-btn
              v-if="$store.getters['auth/isAdminUser'] || $store.getters['auth/isAdmin']"
              :to="{ name: 'usersNew' }"
              color="primary"
            >
              Voeg toe
            </v-btn>
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="filteredUsers"
            :items-per-page="20"
            :footer-props="{ itemsPerPageOptions: [10, 20, 50, 200] }"
            :loading="loading"
            must-sort
            style="white-space: nowrap"
            :sort-by.sync="tableSortBy"
            :sort-desc.sync="tableSortDesc"
          >
            <template #[`item.isAdmin`]="{ item }">
              {{ item.isAdmin ? 'Ja' : 'Nee' }}
            </template>

            <template #[`item.isViewOnly`]="{ item }">
              {{ item.isViewOnly ? 'Ja' : 'Nee' }}
            </template>

            <template #[`item.allLocations`]="{ item }">
              {{ item.metaData.locationIds ? 'Nee' : 'Ja' }}
            </template>

            <template #[`item.type`]="{ item }">
              {{ getHumanizedUserType(item.userType) }}
            </template>

            <template #[`item.organizationTitle`]="{ item }">
              <router-link
                v-if="item.metaData && item.metaData.organizationTitle"
                :to="{ name: 'organizationsShow', params: { id: item.metaData.organizationId }}"
              >
                {{ item.metaData.organizationTitle }}
              </router-link>
            </template>

            <template #[`item.actions`]="{ item }">
              <v-icon
                class="mr-2"
                @click="$router.push({ name: 'usersEdit', params: { id: item._id } })"
              >
                mdi-pencil
              </v-icon>

              <wolk-delete-button
                :question="`Wil je gebruiker ${item.email} verwijderen?`"
                @confirmed="deleteUser(item)"
              />
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

import WolkDeleteButton from '../components/WolkDeleteButton.vue';
import WolkPrivateDataAlert from '../components/WolkPrivateDataAlert.vue';

import {
  getHumanizedUserType,
  createBreadcrumbs,
} from '../helpers/humanizer';

export default {
  components: {
    WolkDeleteButton,
    WolkPrivateDataAlert,
  },
  data() {
    return {
      filter: '',
      loading: false,
      users: [],
      tableSortBy: 'email',
      tableSortDesc: false,
    };
  },
  computed: {
    breadcrumbs() {
      return createBreadcrumbs(
        this.$store.state.app.dashboardHomeName,
        [
          {
            text: 'Gebruikers',
            disabled: true,
            exact: true,
            to: { name: 'users' },
          },
        ],
        false,
      );
    },
    canEditAndDeleteUser() {
      if (this.$store.getters['auth/isAdminUser']) {
        return true;
      }

      if (this.$store.getters['auth/isAdmin']) {
        return true;
      }

      return false;
    },
    headers() {
      const headers = [
        { text: 'Voornaam', value: 'firstName' },
        { text: 'Achternaam', value: 'lastName' },
        { text: 'E-mail', value: 'email' },
        { text: 'Type', value: 'type' },
        { text: 'Admin', value: 'isAdmin', sortable: false },
        { text: 'Alleen lezen', value: 'isViewOnly', sortable: false },
        { text: 'Alle locaties', value: 'allLocations', sortable: false },
      ];

      if (this.$store.getters['auth/isAdmin']) {
        headers.push({ text: 'Organisatie', value: 'organizationTitle', sortable: false });
      }

      if (this.canEditAndDeleteUser) {
        headers.push({ text: 'Acties', value: 'actions', sortable: false });
      }

      return headers;
    },
    filteredUsers() {
      if (!this.filter) {
        return this.users;
      }

      return this
        .users
        .filter((c) => `${c.firstName} ${c.lastName} ${c.email} ${this.$store.getters['auth/isAdmin'] ? c.metaData.organizationTitle : ''} `
          .toLowerCase()
          .includes(this.filter.toLowerCase()));
    },
  },
  beforeMount() {
    this.loadUsers();
  },
  methods: {
    getHumanizedUserType(userType) {
      return getHumanizedUserType(userType);
    },
    loadUsers() {
      this.loading = true;
      const url = `${config.VUE_APP_API_BASE_URL}/users`;

      axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        method: 'get',
      })
        .then((response) => {
          this.users = response.data.users;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteUser(item) {
      const url = `${config.VUE_APP_API_BASE_URL}/users/${item._id}`;

      axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        method: 'patch',
        data: {
          deleted: true,
        },
      })
        .then(() => {
          this.loadUsers();
        });
    },
  },
};
</script>
